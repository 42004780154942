import type { Dispatch, SetStateAction } from 'react'

/**
 * Locale enum
 */
export enum Language {
  Danish = 'da',
  English = 'en',
}

export enum Path {
  Login = '/login',
  MyGSV = '/my-gsv',
  RentalAgreements = '/rental-agreements',
  Economy = '/economy',
  Projects = '/projects',
  UserManagement = '/user-management',
  EnergyDashboard = '/energy-dashboard',
  DataPortal = '/data-portal',
}

export enum GSVInfo {
  BankAccount = '5042-0005145757',
  BankName = 'Jyske Bank',
  CVR = '51457528',
  CreditorEmail = 'kreditor@gsv.dk',
  CreditorPhoneNumber = '46550041',
  DebtorEmail = 'debitor@gsv.dk',
  DebtorPhoneNumber = '46550042',
  Email = 'info@gsv.dk',
  PhoneNumber = '70121315',
}

export enum InvoiceOptions {
  'all' = 1,
  'paid' = 2,
  'awaiting' = 3,
  'partlyPaid' = 4,
  'notPaid' = 5,
  'overdue' = 6,
}

export enum EquipmentStatus {
  'Active' = 1,
  'Creating' = 2,
  'Approved' = 3,
  'ProcessingReturn' = 4,
  'Returned' = 5,
  'ReturnedReceived' = 6,
  'ReturnedOnTheWay' = 7,
  'CustomerPickup' = 8,
  'Error' = 9,
}

export enum EquipmentNotification {
  'Ok' = 0,
  'ErrorReported' = 1,
  'NotUsed' = 2,
  'ExceededEndDate' = 3,
}

export type StatusColors = 'green' | 'yellow' | 'red' | 'gray'

export type SelectOption<T = string> = {
  label: string
  value: T
}

export type Translatable<T = string> = Record<string, T | undefined>

export type SearchCategories = 'products' | 'projects' | 'economy'

export type NotificationStatus = number | null
export type SetNotificationStatus = Dispatch<SetStateAction<NotificationStatus>>

export enum Role {
  'User' = 1,
  'UserManager' = 2,
  'SalesAdmin' = 3,
  'Admin' = 4,
}

export enum Right {
  'Administrator' = 1,
  'SeePrice' = 2,
  'Book' = 3,
  'Return' = 4,
}
